@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot');
  src: url('../../fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf') format('truetype'),
    url('../../fonts/icomoon.woff') format('woff'),
    url('../../fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-curadoria-branco:before {
  content: "\e947";
  color: #fff;
}
.icon-ineditos-branco:before {
  content: "\e948";
  color: #fff;
}
.icon-tag-brand .path1:before {
  content: "\e941";
  color: rgb(0, 27, 58);
}
.icon-tag-brand .path2:before {
  content: "\e942";
  margin-left: -2.6904296875em;
  color: rgb(0, 27, 58);
}
.icon-tag-brand .path3:before {
  content: "\e943";
  margin-left: -2.6904296875em;
  color: rgb(234, 198, 128);
}
.icon-tag-brand .path4:before {
  content: "\e944";
  margin-left: -2.6904296875em;
  color: rgb(35, 156, 191);
}
.icon-tag-brand .path5:before {
  content: "\e945";
  margin-left: -2.6904296875em;
  color: rgb(0, 27, 58);
}
.icon-tag-brand .path6:before {
  content: "\e946";
  margin-left: -2.6904296875em;
  color: rgb(0, 27, 58);
}
.icon-curadoria .path1:before {
  content: "\e938";
  color: rgb(19, 29, 62);
}
.icon-curadoria .path2:before {
  content: "\e939";
  margin-left: -3.41015625em;
  color: rgb(19, 29, 62);
}
.icon-curadoria .path3:before {
  content: "\e93a";
  margin-left: -3.41015625em;
  color: rgb(0, 159, 190);
}
.icon-curadoria .path4:before {
  content: "\e93b";
  margin-left: -3.41015625em;
  color: rgb(0, 159, 190);
}
.icon-curadoria .path5:before {
  content: "\e93c";
  margin-left: -3.41015625em;
  color: rgb(0, 159, 190);
}
.icon-ineditos .path1:before {
  content: "\e93d";
  color: rgb(255, 158, 22);
}
.icon-ineditos .path2:before {
  content: "\e93e";
  margin-left: -3.0703125em;
  color: rgb(32, 34, 61);
}
.icon-ineditos .path3:before {
  content: "\e93f";
  margin-left: -3.0703125em;
  color: rgb(32, 34, 61);
}
.icon-ineditos .path4:before {
  content: "\e940";
  margin-left: -3.0703125em;
  color: rgb(255, 158, 22);
}
.icon-assinatura-presente:before {
  content: "\e908";
}
.icon-caret-left:before {
  content: "\e936";
}
.icon-caret-right:before {
  content: "\e937";
}
.icon-play-alt3:before {
  content: "\e935";
}
.icon-mimo:before {
  content: "\e91f";
}
.icon-seta-chat:before {
  content: "\e922";
}
.icon-box:before {
  content: "\e900";
}
.icon-livros:before {
  content: "\e902";
}
.icon-marcador:before {
  content: "\e904";
}
.icon-poster:before {
  content: "\e905";
}
.icon-revista:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\e907";
}
.icon-youtube:before {
  content: "\e909";
}
.icon-instagram:before {
  content: "\e90a";
}
.icon-play:before {
  content: "\e90b";
}
.icon-android:before {
  content: "\e90c";
}
.icon-apple:before {
  content: "\e90d";
}
.icon-brackets:before {
  content: "\e90e";
}
.icon-chevron_right:before {
  content: "\e90f";
}
.icon-arrow_down:before {
  content: "\e910";
}
.icon-play-alt:before {
  content: "\e911";
}
.icon-done:before {
  content: "\e912";
}
.icon-play-alt2:before {
  content: "\e913";
}
.icon-arrow-left:before {
  content: "\e914";
}
.icon-close:before {
  content: "\e915";
}
.icon-check:before {
  content: "\e916";
}
.icon-crédito:before {
  content: "\e917";
}
.icon-boleto:before {
  content: "\e918";
}
.icon-dados-pessoais:before {
  content: "\e919";
}
.icon-entrega:before {
  content: "\e91a";
}
.icon-cadeado:before {
  content: "\e91b";
}
.icon-amigos:before {
  content: "\e91c";
}
.icon-blog:before {
  content: "\e91d";
}
.icon-telefone:before {
  content: "\e91e";
}
.icon-dados:before {
  content: "\e920";
}
.icon-email:before {
  content: "\e921";
}
.icon-error:before {
  content: "\e923";
}
.icon-delete:before {
  content: "\e924";
}
.icon-edit:before {
  content: "\e925";
}
.icon-duvidas:before {
  content: "\e926";
}
.icon-entrega-menu:before {
  content: "\e927";
}
.icon-assinaturas:before {
  content: "\e928";
}
.icon-app:before {
  content: "\e929";
}
.icon-entrega-caminhao:before {
  content: "\e92a";
}
.icon-download:before {
  content: "\e92b";
}
.icon-caixa:before {
  content: "\e92c";
}
.icon-seta-cima:before {
  content: "\e92d";
}
.icon-seta-baixa:before {
  content: "\e92e";
}
.icon-seta-esquerda:before {
  content: "\e92f";
}
.icon-seta-direita:before {
  content: "\e930";
}
.icon-loja:before {
  content: "\e931";
}
.icon-carteira:before {
  content: "\e932";
}
.icon-planos:before {
  content: "\e933";
}
.icon-sinopse:before {
  content: "\e934";
}
.icon-add:before {
  content: "\ea0a";
}
.icon-logout:before {
  content: "\ea14";
}
.icon-twitter:before {
  content: "\e949";
}
.icon-eye-show:before {
  content: "\e901";
}
.icon-eye-hide:before {
  content: "\e903";
}
.icon-volume-medium:before {
  content: "\ea27";
}
.icon-volume-mute2:before {
  content: "\ea2a";
}
.icon-arrow-down-right2:before {
  content: "\ea3d";
}