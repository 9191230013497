@function pixelToRem($pxsize, $rembase: 15) {
  @return $pxsize / $rembase + rem;
}

@function pixelToEm($pxsize, $rembase: 15) {
  @return $pxsize / $rembase + em;
}

@function ratio($width, $height) {
  @return percentage( $height / $width);
}
