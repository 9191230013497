@import 'reset';
@import 'constants';
@import 'mixins';
@import 'functions';
@import 'fonts';
@import 'icomoon';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html,
body,
div {
  box-sizing: border-box;
  font-family: 'CircularStd', sans-serif;
  font-size: 15px;
}

a {
  cursor: pointer;
  text-decoration: none;
}

// TIPOGRAFIA
h1 {
  font-size: pixelToEm(22);
  line-height: 28px;
}

h2 {
  font-size: pixelToEm(20);
  line-height: 26px;
}

h3 {
  font-size: pixelToEm(18);
  line-height: 23px;
}

p,
select {
  font-size: pixelToEm(14);
  line-height: 19px;
}

span,
a {
  font-size: pixelToEm(13);
  line-height: 15.6px;
  text-decoration: none;
}

strong {
  font-weight: bold;
}

label,
input,
button {
  font-size: pixelToEm(14);
}

aside {
  background-color: $cardLinkBGDesktop;
  height: auto;
}
