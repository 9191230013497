@font-face {
    font-family: 'CircularStd';
    src: url('../../fonts/CircularStd-Bold.eot');
    src: url('../../fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CircularStd-Bold.woff2') format('woff2'),
        url('../../fonts/CircularStd-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std Book';
    src: url('../../fonts/CircularStd-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PlayfairDisplay';
    src: url('../../fonts/PlayfairDisplay-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}
